import { KeycloakProfile } from 'keycloak-js';
import { createReducer, on } from '@ngrx/store';
import * as SummaryActions from './summary.actions';
import { GetSummaryAllScopesResponse } from '@ekowitryna/ekowitryna-esg-calculator-angular-api';
export const summaryFeatureKey = 'summary';

export interface SummaryState {
  summary: GetSummaryAllScopesResponse | null;
  loading: boolean;
  error: any | null;
}

export const initialState: SummaryState = {
  summary: null,
  loading: true,
  error: null,
};

export const summaryReducer = createReducer(
  initialState,
  on(SummaryActions.loadSummarySuccess, (state, action) => ({
    ...state,
    summary: action.summary,
    loading: false,
    error: null,
  })),
  on(SummaryActions.loadSummaryFailure, (state, action) => ({
    ...state,
    summary: null,
    loading: false,
    error: action.error,
  }))
);
